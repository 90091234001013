
import Vue from 'vue';
import { get } from 'vuex-pathify';
import { mapState, mapActions } from 'vuex';

export default Vue.extend({
  name: 'drawer',
  computed: {
    ...mapState('app', ['drawer'])
  },
  methods: {
    ...mapActions('app', ['setDrawer'])
  },
  watch: {
    $route() {
      if (this.drawer && this.$vuetify.breakpoint.mdAndDown)
        this.setDrawer(false);
    }
  }
});
